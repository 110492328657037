<template>
  <div class="default-modal">
    <b-modal v-on="$listeners" v-model="visible" :title="title" size="lg" hide-header-close
             header-class="justify-content-center" @ok.prevent="submitModal">

      <template #modal-title>
        <div style="text-align: center">
          <h1>{{ title }}</h1>
        </div>
      </template>

      <template #default="{ ok }">
        <b-form ref="form" @submit.prevent="ok">
          <ul class="list-group">
            <li class="list-unstyled" v-for="input in inputs">
              <generic-input v-bind="input"
                             :value="$g(record, input.key)"
                             @input="(event) => $s(record, input.key, event)"
                             :disabled="$compute(disabled ? disabled : input.disabled)"/>
            </li>
          </ul>
        </b-form>
      </template>

      <template #modal-footer="{ cancel }">
        <div class="float-right">
          <b-button size="sm"
                    variant="default"
                    @click="cancel">
            {{ $t('button.back') }}
          </b-button>
          <b-button v-if="!$compute(disabled)" size="sm"
                    variant="primary"
                    type="submit"
                    @click.prevent="$refs.form.requestSubmit()">
            {{ $t('button.save') }}
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>

import SearchableSelect from "@/components/searchable_select";
import NumberInput from "@/components/number_input";
import CurrencyInput from "@/components/currency_input";
import GenericInput from "@/components/generic_input";

export default {
  name: "DefaultModal",
  components: {GenericInput, CurrencyInput, NumberInput, SearchableSelect},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: -1,
    },
    title: {
      type: String,
      default: ""
    },
    inputs: {
      type: Array,
      default: () => []
    },
    fetchRecord: {
      type: Function,
      default: (id) => {
        return this.recordDefaultState()
      }
    },
    disabled: {
      type: [Function, Boolean],
      default: false
    },
    submitRecord: {
      type: Function,
      default: () => {
      }
    }
  },
  data() {
    return {
      visible: false,
      record: this.recordDefaultState()
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler() {
        if (this.value) {
          if (this.id > 0) this.record = await this.fetchRecord(this.id) // TODO: catch
          else this.record = this.recordDefaultState()
        }
        this.visible = this.value
      }
    },
    visible: {
      immediate: true,
      handler() {
        this.$emit('input', this.visible)
      }
    }
  },
  computed: {

  },
  methods: {
    recordDefaultState() {
      const result = {}
      this.inputs.forEach((entry) => {
        let value = entry.value
        if (value === undefined) {
          value = ''
          if (entry.type === 'number') value = 0
          if (entry.type === 'currency') value = 0
          if (entry.type === 'select') value = 0
          if (entry.type === 'checkbox') value = false
        }
        result[entry.key] = value // TODO: type based
      })
      return result
    },
    submitModal() {
      this.submitRecord(this.record).then(response => {
        this.visible = false
      })
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>