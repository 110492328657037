<template>
  <div class="datatable">

    <b-row v-if="searchEnabled || $slots.actions || allowedActions.length > 0" class="full-width m-b-sm m-r-xxs">

      <b-col md style="min-width: 40%">
        <b-form-input
            class="search-field"
            v-if="searchEnabled"
            v-model="search"
            type="search"
            :placeholder="$t('tables.search.placeholder')"
            @keyup.enter="baseFilterQuery.search = search"/>
      </b-col>

      <b-col md v-if="$slots.actions">
        <slot name="actions"></slot>
      </b-col>

      <b-col md v-for="action in allowedActions">
        <b-button @click.prevent="action.action"
                  class="float-right mt-sm-0 m-t-xs" variant="primary">
          <font-awesome-icon v-if="action.icon" :icon="action.icon"/>
          {{ action.label }}
        </b-button>
      </b-col>

    </b-row>

    <b-table responsive stacked="md" striped hover :items="records" :fields="translatedColumns" :busy="busy"
             :showEmpty="true">

      <template #empty>
        <div class="text-center my-2">
          <strong class="m-l-sm">{{ $t(emptyText || 'common.empty_label') }}</strong>
        </div>
      </template>

      <template #head()="data">
        <span :style="'float:' + data.align ">
        {{ data.label }}
        </span>
      </template>

      <template #cell(actions)="data">
        <b-dropdown v-if="dataRowActions(data).length > 1" :text="$t('button.action')" variant="primary" no-flip
                    boundary="viewport">
          <b-dropdown-item v-for="action in allowedRowActions(data)" @click.prevent="action.action(data.item)">
            <font-awesome-icon v-if="action.icon" :icon="action.icon"/>
            {{ action.label }}
          </b-dropdown-item>
        </b-dropdown>

        <b-button v-else v-for="action in allowedRowActions(data)" @click.prevent="action.action(data.item)"
                  variant="primary">
          <font-awesome-icon v-if="action.icon" :icon="action.icon"/>
          {{ action.label }}
        </b-button>
      </template>

      <template #cell()="data">
        <span v-html="data.value"/>
      </template>

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner v-if="$compute(spinnerEnabled)" class="align-middle"></b-spinner>
          <strong class="m-l-sm">{{ $t('common.loading') }}...</strong>
        </div>
      </template>

    </b-table>
    <b-row align-v="center" v-show="totalCount > 0">
      <b-col>
        {{ paginationText }}
      </b-col>

      <b-col v-show="totalCount > perPageFirstNumber" style="max-width: 180px;min-width: 150px">
        <b-form-select
            v-model="baseFilterQuery.per_page"
            :options="perPageOptions"
            align="right"
            style="font-size: unset;"
        ></b-form-select>
      </b-col>

      <b-col>
        <b-pagination
            v-show="filteredCount>baseFilterQuery.per_page"
            v-model="baseFilterQuery.page"
            :total-rows="filteredCount"
            :per-page="baseFilterQuery.per_page"
            first-number
            last-number
            align="right"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {EventBus} from "@/eventbus";

export default {
  name: "Datatable",
  props: {
    filterQuery: {
      type: Object,
      default: () => {
        return {}
      }
    },
    fetchRecords: {
      type: Function,
      default: () => {
        return {
          data: [],
          totalCount: 0
        }
      }
    },
    columns: {
      type: Array,
      default: () => []
    },
    actions: { // id, label, action
      type: Array,
      default: () => []
    },
    rowActions: {
      type: Array,
      default: () => []
    },
    emptyText: {
      type: String,
      default: null
    },
    searchEnabled: {
      type: Boolean,
      default: true
    },
    thClass: {
      type: String,
      default: 'text-left'
    },
    tdClass: {
      type: String,
      default: 'text-left'
    },
    registerWebsocket: {
      type: Array,
      default: () => []
    },
    spinnerEnabled: {
      type: [Boolean, Function],
      default: true
    }
  },
  data() {
    return {
      perPageOptions: [
        {value: 10, text: this.$t('common.x_per_page', {x: 10})},
        {value: 25, text: this.$t('common.x_per_page', {x: 25})},
        {value: 50, text: this.$t('common.x_per_page', {x: 50})}
      ],
      records: [],
      baseFilterQuery: {
        page: 1,
        per_page: 25,
        search: ""
      },
      totalCount: 0,
      filteredCount: 0,
      search: "",
      busy: false,
      lastResponse: {},
      currentReloadTimestamp: 0,
      reloadCallback: () => {
        this.reload(false)
      },
      websocketCallback: (data) => {
        if (Object.keys(data).find(key => this.registerWebsocket.includes(key))) {
          const timestamp = new Date().getTime()
          this.currentReloadTimestamp = timestamp
          setTimeout(() => {
            if (timestamp === this.currentReloadTimestamp) this.reload(false)
          }, 5000)
        }
      }
    }
  },
  created() {
    EventBus.$on('datatableReload', this.reloadCallback)
    EventBus.$on('websocketMessage', this.websocketCallback)
  },
  destroyed() {
    EventBus.$off('datatableReload', this.reloadCallback)
    EventBus.$off('websocketMessage', this.websocketCallback)
  },
  watch: {
    baseFilterQuery: {
      immediate: true,
      deep: true,
      handler(oldV, newV) {
        this.reload()
      }
    },
    filterQuery: {
      immediate: false,
      deep: true,
      handler(oldV, newV) {
        this.reload()
      }
    }
  },
  computed: {
    perPageFirstNumber() {
      return this.perPageOptions[0].value
    },
    allowedActions() {
      if (this.actions === undefined) {
        return []
      }
      return this.actions.filter((action) => {
        return action.enabled === undefined || action.enabled //userHasPermission(action.permission)
      })
    },
    allowedColumns() {
      return this.columns.filter((column) => {
        if (typeof column.enabled === 'function') return column.enabled()
        return column.enabled === undefined || column.enabled
      })
    },
    translatedColumns() {
      return this.allowedColumns.map((column) => {
        return {
          ...column,
          label: column.name || (column.label ? this.$t("tables.columns." + column.label) : ''),
          thClass: this.thClass,
          tdClass: this.tdClass
        }
      })
    },
    paginationText() {
      return this.$t(this.totalCount !== this.filteredCount ? 'tables.pagination.text_filtered' : 'tables.pagination.text', {
        first: Math.min((this.baseFilterQuery.page - 1) * this.baseFilterQuery.per_page + 1, this.filteredCount),
        last: Math.min(this.baseFilterQuery.page * this.baseFilterQuery.per_page, this.filteredCount),
        filtered: this.filteredCount,
        total: this.totalCount
      })
    }
  },
  methods: {
    dataRowActions(data) {
      return (this.rowActions || []).concat(data.item.actions || [])
    },
    allowedRowActions(data) {
      return this.dataRowActions(data).filter((action) => {
        if (typeof action.enabled === 'function') return action.enabled() // userHasPermission(action.permission)
        return action.enabled === undefined || action.enabled // userHasPermission(action.permission)
      })
    },
    async reload(loadingIndicator = true) {
      if (loadingIndicator) this.busy = true
      const response = await this.fetchRecords({...this.baseFilterQuery, ...this.filterQuery})
      if (JSON.stringify(response) !== JSON.stringify(this.lastResponse)) {
        this.lastResponse = response
        this.records = response.data || response
        this.totalCount = response.recordsTotal || this.records.length
        this.filteredCount = response.hasOwnProperty('recordsFiltered') ? response.recordsFiltered : this.totalCount
      }
      if (loadingIndicator) this.busy = false
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.table {
  //min-height: 25vh; // current fix for dropdown not fully visible
  &[url] {
    display: none;
  }
}

.td {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word; // probably remove
  white-space: nowrap;
  vertical-align: middle;
}

.table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
  text-align: left;
}

.b-pagination {
  margin-bottom: 0;
}
</style>
