<template>
  <div class="clients">

    <div class="wrapper wrapper-content animated fadeInDown">
      <b-row>

        <b-col class="ibox">
          <div class="ibox-content no-borders b-r-lg">

            <!-- title -->
            <div class="row m-xxs">
              <div class="article">
                <h1>{{ $t("prologistics.name") }} | {{ $t("multiples.client.other") }}</h1>
              </div>
            </div>

            <!-- table -->
            <div class="m-xxs">
              <b-tabs content-class="mt-3" fill>
                <b-tab :lazy="tab.recordsTotal < 0" v-for="tab in tabs" :title="$tabLabel(tab)">
                  <datatable :columns="tab.columns" :fetch-records="tab.fetchFunction" :actions="tab.actions"/>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </b-col>

      </b-row>
    </div>

    <default-modal v-model="recordModalShow" :id="recordId" :title="recordModalTitle"
                   :type="recordModalType" :inputs="modalInputs" :fetch-record="fetchRecord"
                   :disabled="recordModalType==='show'"
                   :submitRecord="submitRecord"
    />
  </div>
</template>

<script>
import Datatable from "@/components/datatable";
import {getClient, getClients, getSelectableRoles, postClient, putClient, removeClient} from "@/services/clients";
import {putEmployee} from "@/services/employees";
import {mapGetters} from "vuex";
import DefaultModal from "@/components/default_modal";

export default {
  components: {DefaultModal, Datatable},
  data() {
    return {
      recordId: 0,
      recordModalShow: false,
      recordModalType: '',
      recordModalTitle: '',
      tabs: [ // TODO: use getSelectableRoles with raw instead of translated values?
        this.getTab(1, "clients.roles.rental", "rental"),
        this.getTab(2, "clients.roles.service", "service"),
        this.getTab(3, "clients.roles.hybrid", "hybrid"),
        this.getTab(4, "clients.roles.driver", "driver")
      ],
      modalInputs: [
        {
          key: "name",
          label: 'common.name',
          required: true
        },
        {
          key: "alias",
          label: 'common.alias'
        },
        {
          key: "address.street_name",
          label: 'common.street_name',
          required: true
        },
        {
          key: "address.postcode",
          label: 'common.postcode',
          required: true
        },
        {
          key: "address.city_name",
          label: 'common.city',
          required: true
        },
        {
          key: "address.country_name",
          label: 'common.country',
          required: true
        },
        {
          key: "email",
          label: 'common.email',
          type: 'email',
          required: true
        },
        {
          key: "phone_number",
          label: 'common.phone_number',
          type: 'text',
          required: true
        },
        {
          key: "discount",
          label: 'common.discount',
          type: 'number',
          required: true,
          min: -100,
          max: 100,
          sign: "%"
        },
        {
          key: "cancellation_fee",
          label: 'common.cancellation_fee',
          type: 'currency'
        },
        {
          key: "transfer_price",
          label: 'common.transfer_price',
          type: 'currency'
        },
        {
          key: "url_terms_of_hire",
          label: 'common.url_terms_of_hire',
          type: 'text',
          value: this.$t('prologistics.terms'),
          required: false
        },
        {
          key: "vat_number",
          label: 'common.vat_number',
          type: 'text',
          required: true
        },
        {
          key: "bank_account.account_owner_name",
          label: 'common.account_owner_name',
          type: 'text',
          required: true
        },
        {
          key: "bank_account.bank_name",
          label: 'common.bank_name',
          type: 'text',
          required: true
        },
        {
          key: "bank_account.iban",
          label: 'common.iban',
          type: 'text',
          required: true
        },
        {
          key: "bank_account.bic",
          label: 'common.bic',
          type: 'text',
          required: true
        },
        {
          key: "role",
          label: 'common.role',
          type: 'select',
          fetchRecords: getSelectableRoles,
          disabled: () => {
            return this.recordModalType !== 'create'
          }
        },
      ]
    }
  },
  methods: {
    getTab(id, label, option) {
      return {
        id: id,
        label: label,
        columns: [
          {key: "id", label: "id",},
          {
            key: "name",
            label: "full_name",
            formatter: (value, key, item) => {
              return item.alias || value
            }
          },
          {
            key: "address.city_name",
            label: "address",
            formatter: (value, key, item) => {
              return value + "<br>" + "<small>" + item?.address?.street_name + ", " + item?.address.postcode + "</small>"
            }
          },
          {key: "phone_number", label: "phone_number"}, // license_plate
          {key: "dispatchers_count", label: "number_of_dispatchers"},
          {key: "drivers_count", label: "number_of_drivers"},
          {
            key: "discount",
            label: "discount",
            formatter: (value, key, item) => {
              return value + "%"
            }
          },
          {
            key: "created_at",
            label: "created_at",
            formatter: this.$options.filters.formatDateDefault
          },
          {
            key: "active",
            label: "active",
            formatter: (value, key, item) => {
              return this.current_user.client_id !== item.id ? "" : this.$t('tables.columns.active')
            }
          },
          {key: "actions", label: "actions"},
        ],
        fetchFunction: async (filterQuery) => {
          filterQuery.option = option
          const response = await getClients(filterQuery)
          this.tabs[id - 1].recordsTotal = response.recordsTotal || 0
          response.data.forEach((entry) => {
            entry.actions = [
              {
                label: this.$t('button.show'),
                action: this.showRecordDetailModal
              },
              {
                label: this.$t('button.change'),
                action: this.changeClient,
                enabled: this.current_user.client_id !== entry.id
              },
              {
                label: this.$t('button.remove'),
                action: this.removeRecord
              }
            ]
          })
          return response
        },
        actions: [

        ],
        recordsTotal: -1
      }
    },
    fetchRecord(id) {
      return getClient(id)
    },
    showRecordDetailModal(entry) {
      this.recordId = entry.id
      this.recordModalTitle = this.$t('clients.show')
      this.recordModalType = 'show'
      this.recordModalShow = true
    },
    async removeRecord(entry) {
      this.$makeRemoveDialog(this).then((r) => {
        if (r) removeClient(entry.id)
      })
    },
    changeClient(entry) {
      putEmployee(entry.id, {client_id: entry.id}).then((response) => {
        window.location.href = "/"
      })
    },
    submitRecord(entry) {
      if (this.recordModalType === 'create') return postClient(entry)
      else return putClient(entry.id, entry)
    }
  },
  computed: {
    ...mapGetters("user", ["current_user"]),
  }
}
</script>